import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserCountPage: React.FC = () => {
  const [userCount, setUserCount] = useState<number>(0);
  const [closedStureportCount, setClosedStureportCount] = useState<number>(0);
  const [totalStureportCount, setTotalStureportCount] = useState<number>(0);
  const [busListCount, setBusListCount] = useState<number>(0);
  const [azpStopCount, setAzpStopCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      // Fetch user count
      const userSnapshot = await getDocs(collection(db, 'users'));
      setUserCount(userSnapshot.size);

      // Fetch total stureports count
      const stureportCollection = collection(db, 'stureports');
      const totalStureportSnapshot = await getDocs(stureportCollection);
      setTotalStureportCount(totalStureportSnapshot.size);

      // Fetch closed stureports count
      const closedStureportSnapshot = await getDocs(query(stureportCollection, where('reportStatus', '==', 'Closed')));
      setClosedStureportCount(closedStureportSnapshot.size);

      // Fetch BusList count
      const busListSnapshot = await getDocs(collection(db, 'BusList'));
      setBusListCount(busListSnapshot.size);

      // Fetch azp_stop count
      const azpStopSnapshot = await getDocs(collection(db, 'azp_stop'));
      setAzpStopCount(azpStopSnapshot.size);
      
    } catch (error) {
      console.error('Error fetching counts:', error);
      toast.error('Error fetching data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = () => {
    toast.info('Refreshing data...', { autoClose: 4000 });
    setTimeout(() => {
      fetchData();
    }, 4000);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen text-4xl font-bold text-gray-200 bg-gray-950">
        Loading...
      </div>
    );
  }

  return (
    <div
      className="relative flex items-center justify-center h-screen bg-gray-900"
      style={{
        backgroundImage: 'url("https://tronova.azmiproductions.com/img/backy.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-80"></div>

      {/* Content */}
      <div className="relative z-10 container mx-auto text-center px-4">
        {/* Logo */}
        <img 
          src="https://tronova.azmiproductions.com/img/pocketnoback.png" 
          alt="Pocket IIUM Logo" 
          className="mx-auto w-32 mb-1"
        />
        
        <h1 className="text-4xl font-extrabold text-white mb-10 drop-shadow-lg">
          Smart Shuttle Overview
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8">
          {/* Card 1: Users */}
          <div className="bg-gray-800 bg-opacity-75 p-6 rounded-xl shadow-lg hover:shadow-2xl transition duration-300 ease-in-out">
            <h2 className="text-lg md:text-xl font-bold text-white mb-2">Users</h2>
            <p className="text-4xl md:text-6xl font-extrabold text-blue-400 drop-shadow-lg">{userCount}</p>
          </div>

          {/* Card 2: Stureports */}
          <div className="bg-gray-800 bg-opacity-75 p-6 rounded-xl shadow-lg hover:shadow-2xl transition duration-300 ease-in-out">
            <h2 className="text-lg md:text-xl font-bold text-white mb-2">Student Reports Solved</h2>
            <p className="text-4xl md:text-6xl font-extrabold text-blue-400 drop-shadow-lg">
              {closedStureportCount} / {totalStureportCount}
            </p>
          </div>

          {/* Card 3: Bus List */}
          <div className="bg-gray-800 bg-opacity-75 p-6 rounded-xl shadow-lg hover:shadow-2xl transition duration-300 ease-in-out">
            <h2 className="text-lg md:text-xl font-bold text-white mb-2">Bus Counts</h2>
            <p className="text-4xl md:text-6xl font-extrabold text-blue-400 drop-shadow-lg">{busListCount}</p>
          </div>

          {/* Card 4: AZP Stop */}
          <div className="bg-gray-800 bg-opacity-75 p-6 rounded-xl shadow-lg hover:shadow-2xl transition duration-300 ease-in-out">
            <h2 className="text-lg md:text-xl font-bold text-white mb-2">Checkpoints</h2>
            <p className="text-4xl md:text-6xl font-extrabold text-blue-400 drop-shadow-lg">{azpStopCount}</p>
          </div>
        </div>

        {/* Refresh Button */}
        <button
          onClick={handleRefresh}
          className="mt-8 bg-blue-600 text-white font-bold py-3 px-6 rounded-xl hover:bg-blue-700 transition duration-300"
        >
          Refresh
        </button>
      </div>

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};

export default UserCountPage;
