import React from 'react';
import UserCountPage from './pages/UserCount';
import { Analytics } from "@vercel/analytics/react"

const App: React.FC = () => {
  return (
    <div>
      <UserCountPage />
      <Analytics/>
    </div>
  );
};

export default App;
