import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where
} from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyAT9ps5CYFv___A2NSrwPMULpGgrJGeYyw",
  authDomain: "shuttlebusiium.firebaseapp.com",
  projectId: "shuttlebusiium",
  storageBucket: "shuttlebusiium.appspot.com",
  messagingSenderId: "147845763016",
  appId: "1:147845763016:web:b795cc8865a86dd3803afd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Export Firestore functions
export { db, collection, getDocs, addDoc, updateDoc, deleteDoc, doc, query, where };
